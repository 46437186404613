import requests from "./httpService";

const ShiftService = {
  getShifts(id, body) {
    return requests.get(`/shift/all?company_id=${id}`, body);
  },
  createShifts(id, body) {
    return requests.post(`/shift/create?company_id=${id}`, body);
  },
  updateShifts(id, body) {
    return requests.post(`/shift/update/${id}`, body);
  },
  updateShiftsStatus(id, body) {
    return requests.post(`/shift/statusupdate/${id}`, body);
  },
  getUserShifts(body) {
    return requests.post(`/user/userallshifts`, body);
  },
  updateUserShifts(body) {
    return requests.post(`/user/userallshiftrecord`, body);
  },
  updateUserShiftsMonth(body) {
    return requests.post(`/user/userallshiftsUpdateMonth`, body);
  },
};

export default ShiftService;