import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker, Button, message } from "antd";
import moment from "moment";
import Sidebar from "../../Layouts/Sidebar";
import PhyicalYearService from "../../../Services/PhyicalYearService";
import DataParser from "../../../utlits/Dataparser";
import CompanyService from "../../../Services/CompanyService";

const AddPhysicalPeriod = () => {
  const dateFormat = "YYYY/MM/DD";
  const timeFormat = "HH:mm";
  const companyid = DataParser.CompanyId();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [shiftTimeStart, setShiftTimeStart] = useState(null);
  const [shiftTimeEnd, setShiftTimeEnd] = useState(null);

  // Handle fiscal year change
  const handleDateChange = (date) => {
    if (!date) return;
    let startDate = moment(date);
    let endDates = moment(startDate).add(364, "days");
    
    setStartDate(startDate);
    setEndDate(endDates);

    let dataBody = {
      company_id: companyid,
      start_date: startDate.format(dateFormat),
      end_date: endDates.format(dateFormat),
    };

    PhyicalYearService.updatePhysicalYear(updateId, dataBody).then(() => {
      message.success("Fiscal year updated successfully");
    });
  };

  // Fetch fiscal period details
  const fetchPhysicalPeriodDetails = () => {
    PhyicalYearService.getPhysicalYearDetails().then((res) => {
      const data = res.data;
      setUpdateId(data.id);
      setStartDate(moment(data.start_date));
      setEndDate(moment(data.end_date));
    });
  };

  // Fetch shift timings
  const fetchShiftDetails = () => {
    CompanyService.getShifttimming(companyid).then((res) => {
      const data = res?.ShitTimings;
      setShiftTimeStart(data?.timeIn ? moment(data.timeIn, timeFormat) : null);
      setShiftTimeEnd(data?.timeOut ? moment(data.timeOut, timeFormat) : null);
    });
  };

  // Update shift timings
  const handleShiftUpdate = () => {
    let dataBody = {
      company_id: companyid,
      timeIn: shiftTimeStart ? shiftTimeStart.format(timeFormat) : "",
      timeOut: shiftTimeEnd ? shiftTimeEnd.format(timeFormat) : "",
    };

    CompanyService.createShifttiming(dataBody).then(() => {
      message.success("Shift timings updated successfully");
    });
  };

  useEffect(() => {
    fetchPhysicalPeriodDetails();
    fetchShiftDetails();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Company Settings</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Fiscal Year Selection */}
                    <div className="mb-3 row">
                      <label className="col-md-2 col-form-label">Fiscal Year</label>
                      <div className="col-md-10">
                        <DatePicker
                          style={{ marginRight: "15px" }}
                          value={startDate}
                          onChange={handleDateChange}
                          picker="month"
                        />
                        <DatePicker value={endDate} picker="month" disabled />
                      </div>
                    </div>

                    {/* Shift Timing Selection */}
                    <div className="mb-3 row">
                      <label className="col-md-2 col-form-label">Shift Timing</label>
                      <div className="col-md-10">
                        <TimePicker
                          format={timeFormat}
                          value={shiftTimeStart}
                          onChange={setShiftTimeStart}
                          style={{ marginRight: "15px" }}
                        />
                        <TimePicker
                          format={timeFormat}
                          value={shiftTimeEnd}
                          onChange={setShiftTimeEnd}
                        />
                        <Button
                          type="primary"
                          style={{ marginLeft: "15px" }}
                          onClick={handleShiftUpdate}
                        >
                          Update Shift Time
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhysicalPeriod;
