import React from "react";
import { Link } from "react-router-dom";
import UserProfile from "./UserProfile.";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UserProfileHrUser from "./UserProfileHrUser";

const UserBox = (props) => {
  const {
    username,
    handelSearch,
    handelReset,
    isLoading,
    Users,
    EmployeDetails,
    GotoUser,
    GotoUserShift,
    handelCheckAttendance,
    handelViewUser,
  } = props;
  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex mb-3">
          <input
            value={username}
            onChange={(e) => handelSearch(e)}
            className="form-control "
            type="search"
            placeholder="Search Employee Name"
          />
          <button
            onClick={(e) => {
              handelReset(e);
            }}
            style={{ marginLeft: "10px" }}
            className="btn btn-color"
          >
            Reset
          </button>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row staff-grid-row">
              {Users.map((es) => (
                <div
                  key={es.id}
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                >
                  <div className="profile-widget">
                    <UserProfileHrUser
                      username={es?.first_name + " " + es.last_name}
                      userImage={es?.image}
                    />

                    <div className="dropdown profile-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="uil-ellipsis-v" />
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => EmployeDetails(e, es.id)}
                        >
                          <i className="fa fa-pencil m-r-5" /> User Details
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => GotoUser(e, es.id)}
                        >
                          <i className="fa fa-trash-o m-r-5" /> Leave Request
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => handelCheckAttendance(e, es)}
                        >
                          <i className="fa fa-trash-o m-r-5" /> Check Attendance
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => handelViewUser(e, es.id)}
                        >
                          <i className="fa fa-trash-o m-r-5" /> Paid/Unpaid
                          Leaves
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => GotoUserShift(e, es.id)}
                        >
                        
                          <i className="uil-user iconscolor m-r-5" /> User Shift
                        </Link>
                      </div>
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                      <span>{es?.first_name + " " + es.last_name}</span>
                    </h4>
                    <div className="small text-muted">
                      {es.departments.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBox;
