import moment from "moment";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import Chart from "react-apexcharts";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import shortid from "shortid";
import { DatePicker } from "antd";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import ShiftService from "../../../Services/ShiftService";
import { toast } from "react-toastify";

const UserShifts = () => {
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const [updatePunchInMonth, setUpdatePunchInMonth] = useState(null);
  const [updatePunchOutMonth, setUpdatePunchOutMonth] = useState(null);
  const [selectMonthUp, setSelectMonthUp] = useState("");
  const [selectMonthUpYear, setSelectMonthUpYear] = useState("");

  const [apiUser, setApiUser] = useState(null);
  const [attendanceList, setAttendenceList] = useState([]);
  const [userEntitlementList, setUserEntitlementsList] = useState([]);
  const [userLeaves, setUserLeaves] = useState([]);
  const [userLeaveDates, setUserLeaveDates] = useState([]);
  const [userLeaveDatesWorkHours, setUserLeaveDatesWorkHours] = useState([]);
  const [workFromHome, setWorkFromHome] = useState(null);
  const [status, setStatus] = useState(null);
  const [isDisabled, setIsDisable] = useState(null);
  const [updatePunchIn, setUpdatePunchIn] = useState(null);
  const [updatePunchOut, setUpdatePunchOut] = useState(null);
  const [editId, setEditId] = useState(null);
  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");
  const compId = DataParser.CompanyId();
  const userid = DataParser.UserId();
  const currentDate = DataParser.getDateinFormat();
  const currentTime = DataParser.getCurrentTime();

  const [empName, setEmpName] = useState("");

  const getUserShifts = async () => {
    if (!selectMonth || !selectYear) return;

    setLoading(true);

    let bodySend = {
      user_id: id,
      year: parseInt(selectYear),
      month: selectMonth,
    };

    try {
      const res = await ShiftService.getUserShifts(bodySend);

      if (res?.Good && res?.data?.dates) {
        setAttendenceList(res.data.dates);
      } else {
        console.error("Invalid response format", res);
      }
    } catch (err) {
      console.error("Error fetching user shifts: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handelEdit = (e, item) => {
    console.log("itemalso:", item);
    setEditId(item);
    let timein = DataParser.convertTime(item.timein);
    let timeout = DataParser.convertTime(item.timeout);
    setUpdatePunchIn(timein);
    setUpdatePunchOut(timeout);
    // alert(item.id);
  };

  const onCancel = (e) => {
    e.preventDefault();
    setUpdatePunchIn(null);
    setUpdatePunchOut(null);
  };

  const handelSearch = (e) => {
    e.preventDefault();
    getUserShifts();
  };

  const onChange = (date, dateString) => {
    let year = dateString.split("-")[0];
    let monthNumber = parseInt(dateString.split("-")[1]);

    let monthName = moment()
      .month(monthNumber - 1)
      .format("MMMM");

    setSelectMonth(monthName);
    setSelectYear(year);
    getUserShifts();
  };

  const handelUpdateShift = (e) => {
    e.preventDefault();
    console.log("editIdL : ", editId);
    let bodySend = {
      user_id: id,
      year: parseInt(selectYear),
      month: selectMonth,
      date: editId?.day,
      timeIn: updatePunchIn,
      timeOut: updatePunchOut,
    };
    ShiftService.updateUserShifts(bodySend)
      .then((res) => {
        console.log("response of updateU serS hifts: ", res);
        getUserShifts();
      })
      .catch((err) => {
        console.log("err");
      });
    setEditId(null);
    setUpdatePunchIn(null);
    setUpdatePunchOut(null);
    var link = document.getElementById("createAttendanceModal");
    link.click();
  };

  const handelUpdateShiftMonth = (e) => {
    e.preventDefault();
    let bodySend = {
      user_id: id,
      year: selectYear,
      month: selectMonth,
      timeIn: updatePunchInMonth,
      timeOut: updatePunchOutMonth,
    };
    ShiftService.updateUserShiftsMonth(bodySend)
      .then((res) => {
        console.log("response of updateU serS hifts: ", res);
        toast.success(res.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
        getUserShifts();
      })
      .catch((err) => {
        console.log("err");
      });
  };
  return (
    <>
      <Helmet>
        <title>Shifts - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Shifts</h4>
                </div>
              </div>

              <div
                className="update-shift-form"
                style={{
                  border: "1px solid #ccc",
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <h5>Update Shift</h5>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handelUpdateShiftMonth(e);
                  }}
                >
                  


                  {/* Month Picker */}
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      Month
                    </label>
                    <input
          type="month"
          className="form-control"
          value={
            selectYear && selectMonth
              ? `${selectYear}-${moment().month(selectMonth).format("MM")}`
              : ""
          }
          onChange={(e) => {
            const value = e.target.value; // format: "YYYY-MM"
            const [year, month] = value.split("-");
            console.log("years is: ", year)
            const monthName = moment().month(parseInt(month, 10) - 1).format("MMMM");
            setSelectYear(year);
            setSelectMonth(monthName);
          }}
        />
                  </div>

                 
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      Time In
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      value={updatePunchInMonth}
                      onChange={(e) => setUpdatePunchInMonth(e.target.value)}
                    />
                  </div>

                 
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      Time Out
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      value={updatePunchOutMonth}
                      onChange={(e) => setUpdatePunchOutMonth(e.target.value)}
                     
                    />
                  </div>

          
                  <div className="button-group">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                    >
                      Save Changes
                    </button>
                  
                  </div>
                </form>
              </div>

              <div className="row mb-4">
                <div className="col-sm-6 col-md-12 d-flex justify-content-end">
                  <DatePicker
                    className="pr-2 "
                    onChange={onChange}
                    picker="month"
                    style={{ marginRight: "10px" }}
                  />
                  <div className="">
                    <Link
                      style={{
                        backgroundColor: "#00b5b8 !important",
                        borderColor: "#00b5b8 !important",
                        color: "white !important",
                        marginRight: "10px",
                      }}
                      onClick={(e) => handelSearch(e)}
                      to="#"
                      className="btn btn-color"
                    >
                      <i class="uil-search"></i> Search
                    </Link>
                  </div>
                </div>
              </div>

              {/* this is for others */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Date</th>
                            <th>Time In</th>
                            {DataParser.userRoleTitle() === "HR" ||
                            DataParser.userRoleTitle() === "Company Admin" ||
                            DataParser.userRoleTitle() === "Sub Admin" ? (
                              <th>Action</th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                                <td>
                                  <Skeleton width="40%" />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {attendanceList.length > 0 ? (
                                attendanceList.map((el) => (
                                  <tr key={shortid.generate()}>
                                    <td className="tableset">
                                      {moment(el.day, "DD-MM-YYYY").format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td className="tableset">{el.timeIn}</td>
                                    <td className="tableset">{el.timeout}</td>
                                    {DataParser.userRoleTitle() === "HR" ||
                                    DataParser.userRoleTitle() ===
                                      "Company Admin" ||
                                    DataParser.userRoleTitle() ===
                                      "Sub Admin" ? (
                                      <td className="tableset">
                                        <Link
                                          to="#"
                                          onClick={(e) => handelEdit(e, el)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#createAttendanceModal"
                                        >
                                          <i className="uil-pen iconscolor" />
                                        </Link>
                                      </td>
                                    ) : null}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                    No shifts found for this month.
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Create modal content */}
      <div
        id="createAttendanceModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <form>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myModalLabel">
                  Update Shift
                </h5>
                <button
                  onClick={(e) => onCancel(e)}
                  type="button"
                  className="btn-close"
                  id="createAttendanceModal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div
                    className="col-12 mb-3 d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-4">
                      <label
                        className="form-label"
                        style={{
                          paddingRight: "20px",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                        htmlFor="pounchin"
                      >
                        time In
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="time"
                        className="form-control"
                        step="3600000"
                        id="pounchin"
                        value={updatePunchIn}
                        onChange={(e) => setUpdatePunchIn(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12 mb-3 d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-4">
                      <label
                        className="form-label"
                        style={{
                          paddingRight: "20px",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                        htmlFor="pounchout"
                      >
                        time Out
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="time"
                        className="form-control"
                        step="3600000"
                        id="pounchout"
                        value={updatePunchOut}
                        onChange={(e) => setUpdatePunchOut(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={(e) => onCancel(e)}
                  className="btn btn-light waves-effect"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={(e) => {
                    handelUpdateShift(e);
                  }}
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Save changes
                </button>
              </div>
            </div>
            {/* /.modal-content modal-dialog-centere */}
          </div>
        </form>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
};

export default UserShifts;
